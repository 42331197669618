.resetContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E1F4FA;
}

.resetWrapper{
    display: flex;
    align-items: center;
    width: 70%;
    height: 50%;
}

.resetDesc{
    font-size: 23px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.694);

}

.resetLeft{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.resetRight{
    flex:1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.resetLogo{
    
    color: rgb(66, 103, 182);
   
}

.resetBox{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color:white ;
    border-radius: 10px;
    width: 80%;
    justify-content: space-between;
    justify-content: space-between;
}

.resetInput{
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    border:1px solid gray;
    padding-left: 10px;
    margin-bottom: 12px;
}

.resetButton{
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    border:1px solid gray;
    background-color: rgb(66, 103, 182);
    color: white;
    font-weight: 500;
    margin-bottom: 12px;
    border: none;
}



.resetLogin{
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background-color: rgb(44, 198, 44);
    color: white;
    font-weight: 500;
    width: 60%;
    align-self: center;
    margin-bottom: 12px;
}

.resetButton,.resetInput,.resetLogin:focus{
    outline: none;
}