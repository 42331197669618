.registerContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E1F4FA;
}

.registerWrapper{
    display: flex;
    align-items: center;
    width: 70%;
    height: 70%;
}

.registerDesc{
    font-size: 23px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.694);

}

.registerLeft{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.registerRight{
    flex:1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.registerLogo{ 
    color: rgb(66, 103, 182);
}

.registerBox{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color:white ;
    border-radius: 10px;
    width: 80%;
    justify-content: space-between;
}

.registerInput{
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    border:1px solid gray;
    padding-left: 10px;
    margin-top: 10px;
}

.registerButton{
    margin-top: 10px;
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    border:1px solid gray;
    background-color: rgb(66, 103, 182);
    color: white;
    font-weight: 500;
    border: none;
}



.registerLogin{
    margin-top: 10px;
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background-color: rgb(44, 198, 44);
    color: white;
    font-weight: 500;
    width: 60%;
    align-self: center;
}

.registerButton,.registerInput,.registerLogin:focus{
    outline: none;
}