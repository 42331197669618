.post {
  margin-top: 30px;
  height: fit-content;
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.63);
  box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.63);
}

.postWrapper {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.postTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.postTopLeft {
  display: flex;
  align-items: center;
}

.postProfileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.postProfileName {
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
}

.verticalDot {
  cursor: pointer;
}

.postDate {
  margin-left: 15px;
  font-size: 12px;
}

.postMid {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.postImage {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  margin-top: 20px;
}

.postBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postBottomLeft {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.postLikeIcon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.postCommentCounter {
  font-size: 15px;
  cursor: pointer;
  border-bottom: 2px dashed gray;
}

.postTopRight {
  position: relative;
}

.postEditContainer {
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: aliceblue;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75);
}

.postEdit {
  display: flex;
  flex-direction: column;
  width: 140px;
}

.postEditItem {
  cursor: pointer;
  padding: 10px 0px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid gray;
}

.postEditItem:hover {
  color: white;
  background-color: rgb(66, 103, 182);
  border-radius: 10px;
}
