.loginContainer {
  display: flex-column;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #e1f4fa;
}

.loginDesc {
  font-size: 23px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.694);
}

.loginWrapper {
  display: flex;
  align-items: center;
  width: 70%;
  height: 70%;
}

.loginLeft {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.loginRight {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loginLogo {
  color: rgb(66, 103, 182);
}

.loginBox {
  display: flex;
  flex-direction: column;
  height: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  width: 80%;
  justify-content: space-between;
}

.loginInput {
  height: 40px;
  border-radius: 5px;
  font-size: 20px;
  border: 1px solid gray;
  padding-left: 10px;
}

.loginButton {
  height: 40px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  border: 1px solid gray;
  background-color: rgb(66, 103, 182);
  color: white;
  font-weight: 500;
}

.loginForget {
  margin: 0px auto;
  cursor: pointer;
  color: rgb(66, 103, 182);
  font-weight: 500;
  font-size: 16px;
}

.loginRegister {
  height: 40px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background-color: rgb(44, 198, 44);
  color: white;
  font-weight: 500;
  width: 60%;
  align-self: center;
}

.loginButton,
.loginInput,
.loginRegister:focus {
  outline: none;
}
