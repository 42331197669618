.profileContainer{
    display: flex;
}
.profileRight{
    flex:9
}
.profileRightBottom{
    display: flex;
}

.profileRightTop{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.profileUserCover{
    display: flex;
    flex-direction: column;
    position: relative;
}

.profileUserCoverImg{
    height: 250px;
    width: 100%;
    object-fit: cover;
}

.profileUserImg{
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    left: 0px;
    right :0px;
    margin: auto;
    top: 140px;
    border: 2px solid white;
}

.profileUserInfo{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profileUserInfoDesc{
    text-align: center;
    font-weight: 300px;
    margin-top: 5px
}

.profileUserInfoName{
    text-align: center;
    font-size: 24px;
}