.messangerContainer{
    display: flex;
    height: calc(100vh-50px);
}


.chatBox{
    position: relative;
    background-color: #e1f4fa;
    height: calc(100vh - 50px);
}

.chatMenuWrapper{
    padding: 10px;
}
.chatBoxWrapper{
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);

}
.chatOnlineWrapper{
    padding: 10px;
}

.chatMenuInput{
    padding: 10px 0px;
    width: 90%;
    border: none;
    border-bottom: 1px solid gray;
}
.chatMenuInput:focus{
    outline: none;
}

.chatBoxTop{
    margin-top: 50px;
    height: calc(100vh - 228px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-right: 8px;
}

.chatBoxBottom{
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    
}
.chatMessageInput{
    height: 40px;
    border: 2px solid #9dd1e181;
    border-radius: 5px;
    padding: 5px;
    outline: none;
}

.chatSubmitButton{
    height: 40px;
    width: 70px;
    background-color: rgb(66, 103, 182);
    color: white;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    font-size: 20px;

}

.noConversation{
    color: gray;
    font-weight: 500;
    margin-top: 50px;
    text-align: center;
    position: absolute;
    top: 10%;
    text-align: center;
    width: 100%;
}

